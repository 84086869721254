import React from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Info from '../components/Info';
import Localisation from '../components/Localisation';

const Home = () => {

    const getCurrentURL = () => {
        return window.location.pathname;
    }
    if (getCurrentURL() === "/") {
        const backgroundSizeUp = () => {
            var scrollPos = window.scrollY / 25;
            const back = document.querySelector(".contain-home .content");
            back.style.backgroundSize = 100 + scrollPos + '%';
        }
        window.addEventListener("scroll", backgroundSizeUp);
    }
    return (
        <div>
            <Header />
            <div className="contain-home">
                <div className="content">
                    <div className="title">
                        <h1>Sert Volant</h1>
                        <a href="#localisation">Découvrir</a>
                    </div>
                </div>
            </div>
            <Localisation />
            <Info />
            <Footer />
        </div>
    );
};

export default Home;