import React from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header';

const Inscription = () => {
    return (
        <div className='inscription-page'>
            <Header />
            <div className="content">
                <h1>S'inscrire</h1>
                <div className="contain">
                    <p>L'inscription est à réaliser uniquement en ligne</p>
                    <p>Vous trouverez un ensemble d'informations utiles avec ce <a href='./documents/Fiche_Informations_SertVolant_2022-2023.pdf'>document</a>.</p>
                    <p>Et également le <a href='./documents/Reglement_interieur_SertVolant_2022-2023.pdf'>règlement intérieur</a>.</p>
                    <div className="deja">
                        <h2>Déjà licencié ?</h2>
                        <p>Si vous étiez déjà licencié l'année passée connectez-vous <a target={"_blank"} rel="noreferrer" href="https://www.myffbad.fr/connexion">ici</a>.</p>
                        <p>Entrer votre numéro de licence. Si vous n'avez pas eu de mot de passe l'année dernière <br />cliquer sur le lien "Pas de mot de passe, ou mot de passe oublié ?".</p>
                        <p>Vous allez recevoir un email pour créer un nouveau mot de passe.</p>
                        <p>Vous pourrez enfin vous connecter sur le site <a href="https://adherer.ffbad.club/SVSMU38" target={"_blank"} rel="noreferrer">adherer.ffbad.club/SVSMU38</a> et vous inscrire.</p>
                    </div>
                    <div className="info">
                        <h2>Nouveau au club</h2>
                        <p>L'inscription est à réaliser uniquement en ligne en utilisant l'adresse suivante : <a href='https://adherer.ffbad.club/SVSMU38' target={"_blank"} rel="noreferrer">adherer.ffbad.club/SVSMU38</a>.</p>
                        <p>Si vous vous inscrivez pour la première fois, vous aurez besoin de fournir un certificat médical valable 3 ans en utilisant uniquement ce <a href='https://gdb.ffbad.org/2020-2021/Src/GDB-02/GUI02.01F01_ADM_CertificatDeNonContre-Indication.pdf'>modèle.</a></p>
                    </div>
                </div>
            </div>
            <Footer />
        </div >
    );
};

export default Inscription;