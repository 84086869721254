import React from 'react';

const Localisation = () => {
    return (
        <div id="localisation" className="localisation">
            <h1 className="title_localisation">Où nous trouver ?</h1>
            <div className="content">
                <p>All. des Petites Maisons, 38410 Saint-Martin-d'Uriage</p>
                <iframe
                    title="Google Maps"
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d752.0466299430294!2d5.834202!3d45.146763!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x6bd24049c57f8f4c!2sSaint%20Martin%20d&#39;Uriage%20badminton!5e1!3m2!1sfr!2sfr!4v1661844787726!5m2!1sfr!2sfr"
                    width="100%"
                    height="500"
                    style={{ border: "2px solid black" }}
                    allowFullScreen
                    loading="lazy">
                </iframe>

            </div>
        </div >
    );
};

export default Localisation;