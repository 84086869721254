import React from 'react';

const Footer = () => {
    return (
        <footer>
            <p>© Sert Volant</p>
            <br />
            <hr />
            <p>Site réalisée par <a href="mailto:dev.nathan.turchet@gmail.com">Nathan Turchet</a></p>
        </footer>
    );
};

export default Footer;