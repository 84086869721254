import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './pages/Home';
import "./assets/styles/index.scss"
import Creneaux from './pages/Creneaux';
import Inscription from './pages/Inscription';
import Error404 from './pages/Error404';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/creneaux" element={<Creneaux />}></Route>
        <Route path="/inscription" element={<Inscription />}></Route>
        <Route path="/*" element={<Error404 />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;