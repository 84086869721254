import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF } from '@fortawesome/free-brands-svg-icons'
import { NavLink } from 'react-router-dom';

const Header = () => {
    return (
        <div className="header" id='header'>
            <div className="left">
                <NavLink to="/"></NavLink>
            </div>
            <div className="right">
                <NavLink to="/">Accueil</NavLink>
                <NavLink to="/creneaux">Créneaux</NavLink>
                <NavLink to="/inscription">S'inscrire</NavLink>
                <NavLink className='facebook-btn' to="https://www.facebook.com/svsmubadminton" target={"_blank"} >
                    <FontAwesomeIcon icon={faFacebookF} />
                </NavLink>
            </div>
        </div>
    );
};

export default Header;