import React from 'react';
import Header from '../components/Header';
import { Helmet } from 'react-helmet';
import Footer from '../components/Footer';
import { useState } from 'react';

const Creneaux = () => {
    const [creneaux] = useState([
        {
            "name": "Primaire",
            "age_min": 6,
            "day": [
                {
                    "day": "Jeudi",
                    "h": "16h45 - 17h45"
                }
            ]
        },
        {
            "name": "Collège",
            "age_min": 11,
            "day": [
                {
                    "day": "Jeudi",
                    "h": "17h30 - 19h"
                }
            ]
        },
        {
            "name": "Lycée",
            "age_min": 15,
            "day": [
                {
                    "day": "Lundi",
                    "info": "Jeu libre",
                    "h": "19h - 20h"
                },
                {
                    "day": "Jeudi",
                    "h": "18h45 - 20h15"
                }
            ]
        },
        {
            "name": "Adultes",
            "age_min": 18,
            "day": [
                {
                    "day": "Lundi",
                    "info": "Jeu libre",
                    "h": "20h - 21h30"
                },
                {
                    "day": "Jeudi",
                    "info": "Jeu libre",
                    "h": "20h - 22h30"
                }
            ]
        }
    ])
    return (
        <div>
            <Helmet>
                <title>Sert Volant SMU | Créneaux</title>
            </Helmet>
            <Header />
            <div className="creneaux">
                <div className="content">
                    <h1>Les Créneaux</h1>
                    {
                        creneaux.map((data, key) => {
                            return (
                                <div key={key} className="contain-creneaux">
                                    <div className="creneaux">
                                        <div className="creneaux-visible">
                                            <div className="info">
                                                <h2>{data.name}</h2>
                                                <p>À partir de {data.age_min} ans</p>
                                            </div>
                                        </div>
                                        <div className="creneaux_content creneaux_content-1">
                                            {data.day.map((day, key) => {
                                                return (
                                                    <div key={key} className="day">
                                                        <h3>{day.day}</h3>
                                                        {day.info && (
                                                            <p className="add-info">{day.info}</p>
                                                        )}
                                                        <p>{day.h}</p>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <Footer />
        </div>

    );
};

export default Creneaux;