import React from 'react';
import { NavLink } from 'react-router-dom';
import Header from '../components/Header';
import { Helmet } from 'react-helmet';

const Error404 = () => {
    const back = window.history.back;
    return (
        <>
            <Helmet>
                <title>Sert Volant SMU | Erreur 404</title>
            </Helmet>
            <Header />
            <div className='page-404'>
                <div className="content">
                    <h1>Oups !</h1>
                    <h3>Page non trouvée</h3>
                    <p>La page que vous recherchez semble introuvable.</p>
                    <p>Vous pouvez revenir à <NavLink to={back}>la page précédente</NavLink> ou aller à <NavLink to="/">la page d'accueil</NavLink>.
                    </p>
                </div>
            </div>
        </>
    );
};

export default Error404;