import React from 'react';

const Info = () => {
    return (
        <div className="social">
            <h2>Nos Partenaires</h2>
            <div className="partenaire">
                <div className="div">
                    <a target="_blank" rel="noreferrer" href="https://centre-thermal.uriage.com/">
                        <img src="images/partenaire/uriage-eau-thermale.png" alt="Uriage Eau Thermale" />
                    </a>
                    <p>Uriage Eau Thermal</p>
                </div>
                <div className="div">
                    <a target="_blank" rel="noreferrer" href="https://www.youbadit.fr/">
                        <img src="images/partenaire/youbadit.png" alt="YouBadit" />
                    </a>
                    <p>Youbadit</p>
                </div>
            </div>
        </div>
    );
};

export default Info;